import { FunctionComponent, HTMLAttributes, PropsWithChildren } from 'react';

import { classnames } from '@/helpers/classnames';

import { LayerHeader } from './LayerHeader';

interface Props extends HTMLAttributes<HTMLElement> {
  className?: string;
  header: React.ReactNode;
  labels: {
    close: string;
  };
  onClose: () => void;
}

export const Layer: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  className,
  header,
  labels,
  onClose,
  ...props
}) => {
  return (
    <section
      {...props}
      aria-modal="true"
      className={classnames('bg-white', className, 'fixed inset-0 flex flex-col')}
      data-name="Layer"
      role="dialog"
    >
      <div className="flex h-full flex-col" role="document">
        <LayerHeader labels={labels} onClose={onClose}>
          {header}
        </LayerHeader>
        <div className="h-full overflow-y-auto overscroll-none">{children}</div>
      </div>
    </section>
  );
};
