import { useCallback, useSyncExternalStore } from 'react';

type MediaQueryCallback = (event: { matches: boolean; media: string }) => void;

export const useMediaQuery = (query: string) => {
  const subscribe = useCallback(
    (callback: MediaQueryCallback) => {
      const matchMedia = window.matchMedia(query);

      matchMedia.addEventListener('change', callback);
      return () => {
        matchMedia.removeEventListener('change', callback);
      };
    },
    [query],
  );

  const getSnapshot = () => window.matchMedia(query).matches;

  return useSyncExternalStore(subscribe, getSnapshot);
};
