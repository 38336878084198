import { ComponentProps, FunctionComponent, HTMLAttributes, MouseEventHandler } from 'react';

import { CloseButton } from '@/components/close-button/CloseButton';
import { ExpandableText } from '@/components/expandable-text/ExpandableText';
import { Heading } from '@/components/heading/Heading';
import { Image } from '@/components/image/Image';
import { classnames } from '@/helpers/classnames';

interface Props extends HTMLAttributes<HTMLElement> {
  className?: string;
  image: ComponentProps<typeof Image>;
  isDarkMode?: boolean;
  labels: {
    close: string;
    expand: string;
    reduce: string;
  };
  links?: { label: string; onClick?: () => void; url: string }[];
  onClose?: MouseEventHandler;
  subtitle?: string;
  text: string;
  title: string;
}

export const SolidCard: FunctionComponent<Props> = ({
  className,
  image,
  isDarkMode = false,
  labels,
  links = [],
  onClose,
  subtitle,
  text,
  title,
  ...props
}) => {
  return (
    <article
      {...props}
      className={classnames(
        'relative h-full min-h-[10rem] bg-lightSand text-black',
        { 'text-white': isDarkMode },
        className,
        'isolate flex flex-col flex-nowrap gap-20 rounded-16 p-16 sm:flex-row sm:p-20',
      )}
      data-name="SolidCard"
    >
      {image.src && (
        <div className="relative aspect-horizontal flex-none overflow-hidden rounded-16 sm:aspect-vertical sm:h-[15rem]">
          <Image {...image} className="h-full w-full object-cover" />
        </div>
      )}
      <div className="flex-1 space-y-12">
        <Heading className="font-serif text-h5" level={3}>
          {title}
        </Heading>
        {subtitle && (
          <Heading className="text-b3 font-semibold" level={4}>
            {subtitle}
          </Heading>
        )}
        {text && (
          <ExpandableText
            labels={{
              expand: labels.expand,
              reduce: labels.reduce,
            }}
          >
            {text}
          </ExpandableText>
        )}
        {Boolean(links?.length) && (
          <ul>
            {links.map((link) => (
              <li key={link.url}>
                <a
                  className="text:black hover:text-middleGrey-active active:text-middleGrey-active focus:text-middleGrey-active border-b text-b3 font-semibold"
                  data-testid="card-link"
                  href={link.url}
                  onClick={link.onClick}
                  rel="noreferrer"
                  target="_blank"
                >
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
      {Boolean(onClose) && (
        <CloseButton
          className="self-center sm:self-start"
          label={labels.close}
          onClick={onClose}
          theme={isDarkMode ? 'whiteStroke' : 'blackStroke'}
        />
      )}
    </article>
  );
};
