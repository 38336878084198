import { ComponentProps, FunctionComponent } from 'react';

import { Card } from '@/components/card/Card';
import { Image } from '@/components/image/Image';
import { formatAPIISOTime } from '@/helpers/dates';

type PartialCard = Partial<Omit<ComponentProps<typeof Card>, 'image'>>;

interface Props extends PartialCard {
  event: PlanningEvent;
  image?: Omit<ComponentProps<typeof Image>, 'src'>;
}

export const ActivityEvent: FunctionComponent<Props> = ({ event, ...props }) => (
  <Card
    {...props}
    asHTMLText
    image={{
      ...props.image,
      src: event.image,
    }}
    text={event.description}
    title={`${formatAPIISOTime(event.startDateTime)} - ${formatAPIISOTime(event.endDateTime)}: ${event.label}`}
  />
);
