import { Button } from '@clubmed/trident-ui/molecules/Buttons/Button';
import { ComponentProps, FunctionComponent } from 'react';

import { useCarousel } from './Carousel.context';

export const CarouselControlNext: FunctionComponent<ComponentProps<typeof Button>> = (props) => {
  const { isNextDisabled, next } = useCarousel();

  return (
    <Button
      groupName="arrows"
      icon="ArrowDefaultRight"
      theme="yellow"
      variant="icon"
      {...props}
      disabled={isNextDisabled}
      onClick={() => next()}
    />
  );
};
