import { FunctionComponent, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivityEvent } from '@/components/activity-event/ActivityEvent';
import { AppLayer } from '@/components/app-layer/AppLayer';
import { PageContainer } from '@/components/page-container/PageContainer';

interface Props {
  event: PlanningEvent;
  onClose: () => void;
}

export const ActivityEventLayer: FunctionComponent<Props> = ({ onClose, event }) => {
  const { t } = useTranslation();
  const id = useId();

  return (
    <AppLayer
      aria-labelledby={id}
      header={<h2 id={id}>{event.label}</h2>}
      labels={{ close: t('Buttons:close.button') }}
      onClose={onClose}
    >
      <PageContainer className="py-40">
        <ActivityEvent event={event} />
      </PageContainer>
    </AppLayer>
  );
};
