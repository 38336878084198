import { sortBy } from 'lodash';

export const mapStaff = (staff: APIStaff): Staff => {
  const mappedStaff = staff.map(({ first_name, description, job, label, picture, categories }) => ({
    categories,
    codes: categories?.map(({ code }) => code),
    description,
    firstName: first_name,
    job,
    label,
    picture,
  }));

  return sortBy(mappedStaff, ({ codes }) => !codes.includes('resort_chief'));
};
