import { AxiosService, EndpointMap } from './axiosService';

export const FACILITIES_ENDPOINT = 'FACILITIES';
export const PLANNING_ENDPOINT = 'PLANNING';
export const PRODUCT_ENDPOINT = 'PRODUCT';
export const STAFF_ENDPOINT = 'STAFF';

export const ENDPOINTS: EndpointMap = {
  FACILITIES: '/v2/products/{product_id}/facilities',
  PLANNING: '/v0/products/{product_id}/resort_planning',
  PRODUCT: '/v1/products/{product_id}',
  STAFF: '/v0/products/{product_id}/go_staff',
};

export const apiClient = new AxiosService({
  baseURL: process.env.REACT_APP_API_HOST || '',
  endpoints: ENDPOINTS,
  apiKey: process.env.REACT_APP_API_KEY || '',
  headers: {
    'Content-Type': 'application/json',
  },
});
