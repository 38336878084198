export const trackEvent = (name: TrackEventName, params: TrackEventParams) => {
  window.clubMedLayer = window.clubMedLayer || [];
  window.clubMedLayer.push({
    event: name,
    ...params,
  });
};

export const trackClickCleanup = (name: string) => {
  trackEvent(name, { event_click: null });
};

export const trackClick = (name: string, params: TrackClickParams) => {
  trackClickCleanup(name);
  trackEvent(name, { event_click: params });
};

export const trackPageView = (name: string, params: TrackViewParams) => {
  const trackData = {
    ...params,
    page_name: name,
    referrer: document.referrer,
  };

  trackEvent('page_view', trackData);
};
