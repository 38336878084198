import { FunctionComponent, useEffect } from 'react';

import { trackPageView } from '@/helpers/tracking';

interface Props {
  locale: string;
  pageName: string;
  params?: Record<string, unknown>;
}

export const TrackPage: FunctionComponent<Props> = ({ locale, pageName, params }) => {
  useEffect(() => {
    trackPageView(pageName, {
      ...params,
      locale,
    });
  }, [locale, pageName, params]);

  return null;
};
