import { FunctionComponent, HTMLAttributes, PropsWithChildren } from 'react';

import { CloseButton } from '@/components/close-button/CloseButton';
import { classnames } from '@/helpers/classnames';

interface Props extends HTMLAttributes<HTMLElement> {
  isDarkMode?: boolean;
  labels: {
    close: string;
  };
  onClose: () => void;
}

export const FullScreen: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  className,
  isDarkMode = false,
  labels,
  onClose,
  ...props
}) => {
  return (
    <section
      {...props}
      aria-modal="true"
      className={classnames('bg-white', className, 'fixed inset-0 isolate flex flex-col')}
      data-name="FullScreen"
      role="dialog"
    >
      <div className="flex h-full flex-col" role="document">
        <CloseButton
          className="absolute end-0 z-1 m-20"
          label={labels.close}
          onClick={onClose}
          theme={isDarkMode ? 'whiteStroke' : 'blackStroke'}
        />
        {children}
      </div>
    </section>
  );
};
