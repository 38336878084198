export const PRODUCT_TYPES = {
  CRUISE: 'CRUISE',
  RESORT: 'VILLAGE',
  TOUR: 'TOUR',
  VILLA: 'VILLA',
};

export const EXCLUSIVE_TYPES = [PRODUCT_TYPES.CRUISE, PRODUCT_TYPES.VILLA];

export const EXCLUSIVE_COMFORT_LEVEL = 5;
