import { FunctionComponent, ComponentProps } from 'react';

import { Frame } from '@/components/frame/Frame';
import { Image } from '@/components/image/Image';

type PartialFrame = Partial<Omit<ComponentProps<typeof Frame>, 'image'>>;

interface Props extends PartialFrame {
  image: Omit<ComponentProps<typeof Image>, 'src'>;
  member: StaffMember;
}

export const StaffMember: FunctionComponent<Props> = ({ image, member, ...props }) => (
  <Frame
    format="square"
    {...props}
    description={member.job}
    image={{
      ...image,
      src: member.picture,
    }}
    label={member.firstName}
  />
);
