import { Icon } from '@clubmed/trident-ui/atoms/Icons/Icon';
import {
  ComponentProps,
  Fragment,
  FunctionComponent,
  HTMLAttributes,
  MouseEventHandler,
} from 'react';

import { Image } from '@/components/image/Image';
import { classnames } from '@/helpers/classnames';

export const aspectRatios = {
  horizontal: 'aspect-horizontal',
  square: 'aspect-square',
  vertical: 'aspect-vertical',
};

interface Props extends HTMLAttributes<HTMLElement> {
  className?: string;
  description?: string;
  format?: 'horizontal' | 'square' | 'vertical';
  href?: string;
  image: ComponentProps<typeof Image>;
  label: string;
  onClick?: MouseEventHandler;
}

export const Frame: FunctionComponent<Props> = ({
  className,
  description,
  format = 'horizontal',
  image,
  label,
  ...props
}) => {
  const isLink = props.href || props.onClick;
  const TagName = isLink ? (props.href ? 'a' : 'button') : 'div';
  const hasImage = Boolean(image?.src);

  return (
    <TagName
      {...props}
      className={classnames(
        className,
        'group relative isolate block overflow-hidden rounded-16 text-start text-white',
        aspectRatios[format],
        { 'bg-lightSand text-black': !hasImage },
      )}
      data-name="Frame"
    >
      {hasImage && (
        <Fragment>
          <Image {...image} className="h-full w-full object-cover" />
          <div className="pointer-events-none absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black/40" />
        </Fragment>
      )}
      {isLink && (
        <div
          className="absolute bottom-0 end-0 m-24 flex h-48 w-48 shrink-0 items-center justify-center rounded-pill border border-solid border-white bg-transparent font-sans text-b3 font-semibold text-white transition-colors duration-200 group-hover:bg-white group-hover:text-black"
          data-testid="Icon"
        >
          <Icon width="24px" name="ArrowDefaultRight" />
        </div>
      )}
      <div className="absolute bottom-0 w-full p-24 pe-80">
        <p className="text-b2 font-semibold">{label}</p>
        {Boolean(description) && <p className="text-b3">{description}</p>}
      </div>
    </TagName>
  );
};
