import { useCallback, useEffect, useState } from 'react';

interface ScrollPosition {
  x: number;
  y: number;
}

export const useWindowScroll = () => {
  const [position, setPosition] = useState<ScrollPosition>({
    x: 0,
    y: 0,
  });

  const scrollTo = useCallback(({ x, y }: Partial<ScrollPosition>) => {
    const scrollOptions: ScrollToOptions = { behavior: 'smooth' };

    if (typeof x === 'number') {
      scrollOptions.left = x;
    }

    if (typeof y === 'number') {
      scrollOptions.top = y;
    }

    window.scrollTo(scrollOptions);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setPosition({ x: window.scrollX, y: window.scrollY });
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [position, scrollTo] as const;
};
