import { createContext, FunctionComponent, PropsWithChildren, useContext, useState } from 'react';

interface Context {
  isECMode: boolean;
  setIsECMode: (active: boolean) => void;
}

export const ExperienceContext = createContext<Context | null>(null);

interface Props {
  product: Pick<Product, 'id' | 'comfort'>;
}

export const ExperienceProvider: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  product,
}) => {
  const [isECMode, setIsECMode] = useState(product.comfort.isExclusiveCollection);

  const value = { isECMode, setIsECMode };

  return <ExperienceContext.Provider value={value}>{children}</ExperienceContext.Provider>;
};

export const useExperience = () => {
  const context = useContext(ExperienceContext);

  if (context === null) {
    throw new Error('useExperience() should be used in ExperienceProvider');
  }

  return context;
};
