import { FunctionComponent, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { AppLayer } from '@/components/app-layer/AppLayer';
import { PageContainer } from '@/components/page-container/PageContainer';
import { StaffMembers } from '@/components/staff-members/StaffMembers';

interface Props {
  onClose: () => void;
  staff: Staff;
}

export const StaffLayer: FunctionComponent<Props> = ({ onClose, staff }) => {
  const { t } = useTranslation();
  const id = useId();

  return (
    <AppLayer
      aria-labelledby={id}
      header={<h2 id={id}>{t('Titles:title.goTeam')}</h2>}
      labels={{ close: t('Buttons:close.button') }}
      onClose={onClose}
    >
      <PageContainer className="py-40">
        <StaffMembers staff={staff} />
      </PageContainer>
    </AppLayer>
  );
};
