import { ComponentProps, FunctionComponent, useEffect } from 'react';

import { getProductEventsQuery, mapProductEvents } from '@/api/events/events';
import { EventsCarousel } from '@/components/events-carousel/EventsCarousel';
import { SolidCardCarousel } from '@/components/solid-card-carousel/SolidCardCarousel';
import { useApp } from '@/contexts/AppContext';
import { useDirectus } from '@/hooks/use-service/useDirectus';

export const ProductEvents: FunctionComponent<
  Omit<ComponentProps<typeof SolidCardCarousel>, 'items'>
> = (props) => {
  const { locale, productId } = useApp();

  const { data: events, error, fetch, isLoading } = useDirectus({ mapper: mapProductEvents });

  useEffect(() => {
    if (productId) {
      fetch({
        queryParams: {
          query: getProductEventsQuery({ locale, productId }),
        },
      });
    }
  }, [fetch, locale, productId]);

  return <EventsCarousel {...props} error={error} isLoading={isLoading} items={events || []} />;
};
