import { FunctionComponent, HTMLAttributes, PropsWithChildren, useCallback } from 'react';

import { KEY_CODES } from '@/constants/keyboard';
import { classnames } from '@/helpers/classnames';

import { useCarousel } from './Carousel.context';

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  onPrevious?: () => void;
  onNext?: () => void;
}

export const CarouselRoot: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  className,
  ...props
}) => {
  const { next, previous } = useCarousel();

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      switch (e.key) {
        case KEY_CODES.ARROW_RIGHT:
        case KEY_CODES.ARROW_DOWN:
          e.preventDefault();
          next();
          return;
        case KEY_CODES.ARROW_LEFT:
        case KEY_CODES.ARROW_UP:
          e.preventDefault();
          previous();
          return;
        default:
          return;
      }
    },
    [next, previous],
  );

  return (
    <div
      {...props}
      aria-roledescription="carousel"
      className={classnames('relative isolate w-full', className)}
      data-name="Carousel"
      onKeyDownCapture={handleKeyDown}
      role="region"
    >
      {children}
    </div>
  );
};
