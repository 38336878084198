import { Children, ComponentProps, FunctionComponent, PropsWithChildren, RefObject } from 'react';
import { FocusOn } from 'react-focus-on';

import { Layer } from '@/components/layer/Layer';
import { Portal } from '@/components/portal/Portal';
import { classnames } from '@/helpers/classnames';

interface Props extends ComponentProps<typeof Layer> {
  included?: (HTMLElement | RefObject<HTMLElement>)[];
  portalId?: string;
}

export const AppLayer: FunctionComponent<PropsWithChildren<Props>> = ({
  included,
  portalId,
  ...props
}) => {
  if (!Children.count(props.children)) {
    return null;
  }

  return (
    <Portal targetId={portalId}>
      <FocusOn allowPinchZoom enabled onEscapeKey={props.onClose} shards={included}>
        <Layer
          {...props}
          className={classnames(props.className, 'ease animate-toastEnter duration-300')}
        >
          {props.children}
        </Layer>
      </FocusOn>
    </Portal>
  );
};
