import { Children, FunctionComponent, HTMLAttributes, PropsWithChildren } from 'react';

import { classnames } from '@/helpers/classnames';

import { useCarousel } from './Carousel.context';

interface Props extends HTMLAttributes<HTMLDivElement> {
  itemClassName?: string;
  spacingClassName?: string;
}

export const CarouselBody: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  className,
  itemClassName,
  spacingClassName,
  ...props
}) => {
  const { current, onScroll, setCurrentRef, scrollAreaRef } = useCarousel();

  return (
    <div
      {...props}
      className={classnames('w-full', className, 'overflow-hidden')}
      data-name="CarouselBody"
    >
      <div
        className={classnames(
          'items-start',
          spacingClassName,
          'scroll-hidden flex snap-x snap-mandatory flex-row flex-nowrap overflow-x-auto overscroll-none scroll-smooth sm:overscroll-auto',
        )}
        data-testid="CarouselBody-scroll"
        onScroll={onScroll}
        ref={scrollAreaRef}
      >
        {Children.map(children, (child, i) => {
          const isCurrent = i === current;

          return (
            <div
              aria-hidden={!isCurrent}
              aria-roledescription="slide"
              className={classnames(
                itemClassName,
                'will-change-opacity duration-250 w-full flex-none snap-center snap-always transition-opacity ease-in sm:opacity-30',
                {
                  'sm:opacity-100': isCurrent,
                },
              )}
              // @ts-expect-error : should be fixed in react 19
              inert={isCurrent ? undefined : 'true'}
              key={i}
              ref={isCurrent ? (newRef) => setCurrentRef(newRef) : null}
            >
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
};

CarouselBody.displayName = 'CarouselBody';
