import { ComponentProps, FunctionComponent, PropsWithChildren } from 'react';

import { Spoiler } from '@/components/spoiler/Spoiler';
import { Text } from '@/components/text/Text';

interface Props extends ComponentProps<typeof Spoiler> {
  asHTML?: boolean;
  children: string;
}

export const ExpandableText: FunctionComponent<PropsWithChildren<Props>> = ({
  asHTML = false,
  children,
  ...props
}) => {
  return (
    <Spoiler {...props}>
      <Text asHTML={asHTML}>{children}</Text>
    </Spoiler>
  );
};
