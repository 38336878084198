import { FunctionComponent, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Introduction } from '@/components/introduction/Introduction';

interface Props extends HTMLAttributes<HTMLDivElement> {
  product: Pick<Product, 'comfort' | 'description' | 'id' | 'name'>;
}

export const ProductIntroduction: FunctionComponent<Props> = ({ product, ...props }) => {
  const { t } = useTranslation();

  const iconName = product.comfort.isExclusiveCollection
    ? `${product.id}_EC_Outline`
    : `${product.id}_Outline`;

  return (
    <Introduction
      {...props}
      iconName={iconName}
      labels={{ expand: t('Buttons:expandtext.button'), reduce: t('Buttons:collapseText.button') }}
      text={product.description}
      title={t('Titles:title.welcomeIn', { product_id: product.name })}
    />
  );
};
