import { LocaleRouter } from '@/components/LocaleRouter/LocaleRouter';
import { Layout } from '@/pages/Layout';

const App = () => {
  return (
    <Layout>
      <LocaleRouter />
    </Layout>
  );
};

export default App;
