import { FunctionComponent, HTMLAttributes } from 'react';

import { classnames } from '@/helpers/classnames';

export const Skeleton: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={classnames(
        'h-48 w-full rounded-16 bg-lightGrey',
        'animate-fadingPulse',
        className,
      )}
      data-name="Skeleton"
    />
  );
};
