import { directusClient, ROOT_ENDPOINT } from '@/api/DirectusClient';
import { useService, Params } from '@/hooks/use-service/useService';

export interface IUseDirectus<T, K> {
  headers?: { [key: string]: string };
  mapper: (data: T) => K;
  params?: Params;
}

export const useDirectus = <T, K>(props: IUseDirectus<T, K>) => {
  return useService({
    client: directusClient,
    url: ROOT_ENDPOINT,
    ...props,
  });
};
