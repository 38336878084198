import { FunctionComponent, HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivitiesLayer } from '@/components/activities-layer/ActivitiesLayer';
import { Frame } from '@/components/frame/Frame';
import { TRACK_EVENT_NAMES } from '@/constants/tracking';
import { useExperience } from '@/contexts/ExperienceContext';
import { classnames } from '@/helpers/classnames';
import { filterFacilitiesByTypes } from '@/helpers/facilities';
import { trackClick } from '@/helpers/tracking';

interface Props extends HTMLAttributes<HTMLElement> {
  facilities: Facilities;
}

type Group = { id: string; image: string; items: Facilities; label: string };

export const ActivitiesGroups: FunctionComponent<Props> = ({ className, facilities, ...props }) => {
  const [active, setActive] = useState<Group | null>(null);
  const { t } = useTranslation();
  const { isECMode } = useExperience();

  const activities = facilities.filter(
    filterFacilitiesByTypes(
      ['GOLF', 'SPORT', 'TENNIS', 'SWIMMING_POOL', 'SPORT_FACILITY', 'WELLNESS'],
      isECMode,
    ),
  );
  const chilcares = facilities.filter(filterFacilitiesByTypes(['CHILDREN'], isECMode));
  const excursions = facilities.filter(filterFacilitiesByTypes(['EXCURSIONS'], isECMode));

  const groups: Group[] = [
    {
      id: 'SPORT_WELLNESS',
      image: 'https://ns.clubmed.com/ipm/LP_QR_Code_images/Sport&Wellness.jpg',
      items: activities,
      label: t('Titles:title.sportsAndWellness'),
    },
    {
      id: 'CHILDREN',
      image: 'https://ns.clubmed.com/ipm/LP_QR_Code_images/Kids&Family.jpg',
      items: chilcares,
      label: t('Titles:title.kidsAndFamily'),
    },
    {
      id: 'EXCURSIONS',
      image: excursions?.[0]?.image?.src,
      items: excursions,
      label: t('Text:text.otherFacilitiesExcursions'),
    },
  ].filter((g) => Boolean(g.items?.length));

  return (
    <section
      {...props}
      className={classnames(className, 'grid grid-cols-1 gap-20 sm:grid-cols-2 md:grid-cols-3')}
      data-name="ActivitiesGroups"
    >
      {groups.map((group) => (
        <Frame
          format="square"
          image={{
            src: group.image,
          }}
          key={group.id}
          label={group.label}
          onClick={() => {
            setActive(group);
            trackClick(TRACK_EVENT_NAMES.CLICK_TAB, {
              detail_click: 'cards_activities',
              activity_type: group.id.toLowerCase(),
            });
          }}
        />
      ))}
      {active && (
        <ActivitiesLayer
          activities={active.items}
          header={active.label}
          onClose={() => setActive(null)}
        />
      )}
    </section>
  );
};
