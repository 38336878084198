import { throttle } from 'lodash';
import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

interface Context {
  current: number;
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
  next: () => void;
  onScroll: () => void;
  previous: () => void;
  scrollAreaRef: RefObject<HTMLDivElement>;
  setCurrentRef: (ref: HTMLDivElement | null) => void;
}

const CarouselContext = createContext<Context | null>(null);

interface Props {
  initialIndex: number;
  length: number;
  onPrevious?: () => void;
  onNext?: () => void;
}

export const CarouselProvider: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  initialIndex = 0,
  length = 0,
  onNext,
  onPrevious,
}) => {
  const scrollAreaRef = useRef<HTMLDivElement | null>(null);
  const [current, setCurrent] = useState(0);
  const [currentRef, setCurrentRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    scrollToPosition(initialIndex);
  }, [initialIndex]);

  const updateCurrent = (index: number) => {
    setCurrent(() => {
      if (index < 0) {
        return 0;
      }

      if (index >= length) {
        return length - 1;
      }

      return index;
    });
  };

  const onScroll = throttle(() => {
    if (scrollAreaRef.current) {
      const { offsetWidth, scrollLeft } = scrollAreaRef.current;
      const index = Math.round(scrollLeft / offsetWidth);
      updateCurrent(index);

      if (Number.isInteger(scrollLeft / offsetWidth)) {
        scrollAreaRef.current
          .querySelectorAll('[aria-roledescription="slide"]')
          .forEach((e) => e.setAttribute('tabIndex', ''));
        currentRef?.setAttribute('tabIndex', '-1');
        currentRef?.focus();
      }
    }
  }, 250);

  const scrollToPosition = (value: number) => {
    if (scrollAreaRef.current) {
      const scrollWidth = scrollAreaRef.current.offsetWidth;
      scrollAreaRef.current.scrollTo({
        behavior: 'smooth',
        left: scrollAreaRef.current.scrollLeft + scrollWidth * value,
      });
    }
  };

  const handleNext = () => {
    scrollToPosition(+1);
    onNext?.();
  };

  const handlePrevious = () => {
    scrollToPosition(-1);
    onPrevious?.();
  };

  const value = {
    current,
    isNextDisabled: current >= length - 1,
    isPreviousDisabled: current === 0,
    next: handleNext,
    onScroll,
    previous: handlePrevious,
    scrollAreaRef,
    setCurrentRef,
  };

  return <CarouselContext.Provider value={value}>{children}</CarouselContext.Provider>;
};

export const useCarousel = () => {
  const context = useContext(CarouselContext);

  if (context === null) {
    throw new Error('useCarousel() should be used in CarouselProvider');
  }

  return context;
};
