import { Fragment, FunctionComponent } from 'react';

import { FacilityLayer } from '@/components/facility-layer/FacilityLayer';
import { Frame } from '@/components/frame/Frame';
import { useDisclosure } from '@/hooks/use-disclosure/useDisclosure';

interface Props {
  facility: Facility;
  onClick?: () => void;
}

export const Facility: FunctionComponent<Props> = ({ facility, onClick }) => {
  const [isOpen, { close, open }] = useDisclosure(false);

  return (
    <Fragment>
      <Frame
        format="square"
        image={{
          ...facility.image,
          sizes:
            '(max-width: 640px) 100vw, (max-width: 768px) 50vw, (max-width: 1220px) 33vw, 380px',
        }}
        label={facility.name}
        onClick={() => {
          open();
          onClick?.();
        }}
      />
      {isOpen && <FacilityLayer onClose={close} facility={facility} />}
    </Fragment>
  );
};
