import { AxiosService, EndpointMap } from './axiosService';

export const ROOT_ENDPOINT = 'ROOT_ENDPOINT';

export const DIRECTUS_ENDPOINTS: EndpointMap = {
  ROOT_ENDPOINT: '/',
};

export const directusClient = new AxiosService({
  baseURL: process.env.REACT_APP_DIRECTUS_API_HOST || '',
  endpoints: DIRECTUS_ENDPOINTS,
  apiKey: process.env.REACT_APP_DIRECTUS_API_KEY || '',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_DIRECTUS_API_KEY}`,
  },
});
