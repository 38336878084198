import { Icon } from '@clubmed/trident-ui/atoms/Icons/Icon';
import { FunctionComponent, HTMLAttributes } from 'react';

import { ExpandableText } from '@/components/expandable-text/ExpandableText';
import { Heading } from '@/components/heading/Heading';
import { classnames } from '@/helpers/classnames';

interface Props extends HTMLAttributes<HTMLDivElement> {
  iconName: string;
  labels: {
    expand: string;
    reduce: string;
  };
  onClose?: () => void;
  onOpen?: () => void;
  text: string;
  title: string;
}

export const Introduction: FunctionComponent<Props> = ({
  className,
  iconName,
  labels,
  onClose,
  onOpen,
  text,
  title,
  ...props
}) => {
  return (
    <div
      {...props}
      className={classnames(className, 'flex flex-col items-center gap-x-20 gap-y-12 md:flex-row')}
      data-name="Introduction"
    >
      <div className="w-[140px] shrink-0 md:w-[220px]">
        <Icon name={iconName} width="100%" />
      </div>
      <div className="space-y-12">
        <Heading level={1} className="font-serif text-h4 sm:text-h3">
          {title}
        </Heading>
        <ExpandableText
          labels={{
            expand: labels.expand,
            reduce: labels.reduce,
          }}
          onClose={onClose}
          onOpen={onOpen}
        >
          {text}
        </ExpandableText>
      </div>
    </div>
  );
};
