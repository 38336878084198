import { Button } from '@clubmed/trident-ui/molecules/Buttons/Button';
import { ComponentProps, FunctionComponent } from 'react';

import { useCarousel } from './Carousel.context';

export const CarouselControlPrevious: FunctionComponent<ComponentProps<typeof Button>> = (
  props,
) => {
  const { previous, isPreviousDisabled } = useCarousel();

  return (
    <Button
      groupName="arrows"
      icon="ArrowDefaultLeft"
      theme="yellow"
      variant="icon"
      {...props}
      disabled={isPreviousDisabled}
      onClick={() => previous()}
    />
  );
};
