import { FunctionComponent, HTMLAttributes, useEffect } from 'react';

import { FACILITIES_ENDPOINT } from '@/api/APIClient';
import { mapFacilities } from '@/api/facilities/facilitiesMapper';
import { ActivitiesGroups } from '@/components/activities-groups/ActivitiesGroups';
import { FramesGridSkeleton } from '@/components/frames-grid-skeleton/FramesGridSkeleton';
import { useApp } from '@/contexts/AppContext';
import { useAPI } from '@/hooks/use-service/useAPI';

export const ProductActivities: FunctionComponent<HTMLAttributes<HTMLElement>> = (props) => {
  const { productId } = useApp();

  const {
    data: facilities,
    error,
    fetch,
    isLoading,
  } = useAPI({ mapper: mapFacilities, url: FACILITIES_ENDPOINT });

  useEffect(() => {
    if (productId) {
      fetch({
        queryParams: {
          filter:
            'type==CHILDREN,GOLF,SPORT,TENNIS,SWIMMING_POOL,SPORT_FACILITY,WELLNESS,EXCURSIONS',
          extended_catalog: true,
        },
      });
    }
  }, [fetch, productId]);

  if (isLoading) {
    return <FramesGridSkeleton />;
  }

  if (error || !facilities) {
    return null;
  }

  return <ActivitiesGroups {...props} facilities={facilities} />;
};
