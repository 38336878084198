import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Image } from '@/components/image/Image';
import { TrackPage } from '@/components/track-page/TrackPage';
import { TRACK_PAGE_NAMES } from '@/constants/tracking';
import { useApp } from '@/contexts/AppContext';

export const NotFoundPage: FunctionComponent = () => {
  const { locale } = useApp();
  const { t } = useTranslation();

  return (
    <main className="relative isolate min-h-screen" role="main">
      <TrackPage locale={locale} pageName={TRACK_PAGE_NAMES.ERROR_PAGE} />
      <div className="absolute inset-0">
        <Image
          className="h-full w-full object-cover"
          sizes="100vw"
          src="https://ns.clubmed.com/dream/BU_Media/PRODUCT_CENTER_DESTINATIONS_SUN_Ocean_Indien_&_Asie_Thailande_Phuket_394360-shj3bunfc9-swhr.jpg"
        />
      </div>
      <h1 className="absolute inset-0 z-1 flex flex-col items-center justify-center space-y-12 px-20 text-center font-serif text-h2 text-white">
        {t('Text:text.pageNotAvailable')}
      </h1>
    </main>
  );
};
