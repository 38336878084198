import { FunctionComponent, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading } from '@/components/heading/Heading';
import { Text } from '@/components/text/Text';
import { classnames } from '@/helpers/classnames';
import { formatAPITime } from '@/helpers/dates';
import { getCurrentWeekDay, isNow } from '@/helpers/program';

const DAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

interface Props extends HTMLAttributes<HTMLDivElement> {
  days: OpeningDays;
}

export const OpeningDays: FunctionComponent<Props> = ({ className, days, ...props }) => {
  const { t } = useTranslation();

  if (!days.length) {
    return null;
  }

  const currentWeekDay = getCurrentWeekDay();

  return (
    <section {...props} className={classnames(className, 'space-y-20')} data-name="OpeningDays">
      <Heading className="text-b2 font-semibold" level={3}>
        {t('Titles:title.openingHours')}
      </Heading>
      <div className="columns-1 gap-60 space-y-8 md:columns-2 lg:columns-3">
        {DAYS.map((DAY) => {
          const { day, hours = [] } = days.find(({ day }) => day === DAY) || {};

          const isClosed = Boolean(!hours.length);
          const isToday = day === currentWeekDay;

          return (
            <article
              className="flex break-inside-avoid-column justify-between gap-20 text-b3"
              key={day}
            >
              <Heading className="font-semibold" level={4}>
                {t(`Text:text.${DAY.toLowerCase()}`)}
              </Heading>
              {isClosed && (
                <p className="font-semibold text-red">{t('Schedules:schedules.closed')}</p>
              )}
              {!isClosed && (
                <ul>
                  {hours
                    .sort((a, b) => (a.opening < b.opening ? -1 : 1))
                    .map(({ closing, opening }) => (
                      <li
                        key={opening}
                        className={classnames({
                          'font-bold': isToday && isNow({ closing, opening }),
                        })}
                      >
                        {[formatAPITime(opening), formatAPITime(closing)].join(' – ')}
                      </li>
                    ))}
                </ul>
              )}
            </article>
          );
        })}
      </div>
      <Text className="text-b4 text-grey">{t('Buttons:disclamer.OpeningHours')}</Text>
    </section>
  );
};
