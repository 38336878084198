import { ComponentProps, FunctionComponent, HTMLAttributes, ReactNode } from 'react';

import { Heading } from '@/components/heading/Heading';
import { Image } from '@/components/image/Image';
import { Text } from '@/components/text/Text';
import { classnames } from '@/helpers/classnames';

export const aspectRatios = {
  horizontal: 'aspect-horizontal',
  square: 'aspect-square',
  vertical: 'aspect-vertical',
};

interface Props extends HTMLAttributes<HTMLDivElement> {
  asHTMLText?: boolean;
  children?: ReactNode;
  format?: 'horizontal' | 'square' | 'vertical';
  image: ComponentProps<typeof Image>;
  text: string;
  title: string;
}

export const Card: FunctionComponent<Props> = ({
  asHTMLText = false,
  children,
  className,
  format = 'square',
  image,
  text,
  title,
  ...props
}) => (
  <div
    {...props}
    className={classnames(className, 'flex flex-col gap-x-40 gap-y-20 md:flex-row')}
    data-name="Card"
  >
    <div
      className={classnames(
        aspectRatios[format],
        'relative w-full flex-none overflow-hidden rounded-16 bg-pearl md:w-[380px]',
      )}
      data-name="Card-image"
    >
      <Image {...image} className="h-full w-full object-cover" />
    </div>
    <div>
      <Heading className="mb-12 text-b2 font-semibold">{title}</Heading>
      <Text className="text-b3" asHTML={asHTMLText}>
        {text}
      </Text>
      {children}
    </div>
  </div>
);
