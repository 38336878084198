import { Fragment, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivityEventLayer } from '@/components/activity-event-layer/ActivityEventLayer';
import { PlanningList } from '@/components/planning-list/PlanningList';
import { formatAPIISOTime } from '@/helpers/dates';

interface Props {
  onClick?: () => void;
  planning: Planning;
}

export const DayPlanning: FunctionComponent<Props> = ({ onClick, planning }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState<PlanningEvent | null>(null);

  const items = planning.map((activity) => ({
    endTime: formatAPIISOTime(activity.endDateTime),
    id: activity.id,
    label: activity.label,
    onClick: () => {
      setActive(activity);
      onClick?.();
    },
    startTime: formatAPIISOTime(activity.startDateTime),
  }));

  return (
    <Fragment>
      <PlanningList items={items} labels={{ see: t('Text:text.ProgramSee') }} />
      {active && <ActivityEventLayer event={active} onClose={() => setActive(null)} />}
    </Fragment>
  );
};
