import { Icon } from '@clubmed/trident-ui/atoms/Icons/Icon';
import { Button } from '@clubmed/trident-ui/molecules/Buttons/Button';
import { FunctionComponent, HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from '@/components/select/Select';
import { TRACK_EVENT_NAMES } from '@/constants/tracking';
import { useApp } from '@/contexts/AppContext';
import { classnames } from '@/helpers/classnames';
import { getLanguages } from '@/helpers/languages';
import { trackClick } from '@/helpers/tracking';

export const LanguagesForm: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  const { t } = useTranslation();

  const { availableLocales, locale, changeLanguage } = useApp();
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(locale);
  const languages = availableLocales.map((lang) => ({
    id: lang,
    value: lang,
    label: getLanguages(t)[lang],
  }));

  return (
    <div
      {...props}
      className={classnames('relative', className, 'isolate')}
      data-name="LanguagesForm"
    >
      <div className="absolute start-1/2 top-[-25%] aspect-square h-[17.5rem] -translate-x-1/2 rounded-full bg-saffron" />
      <Icon
        className="absolute start-1/2 top-0 z-2 -mt-[83px] -translate-x-1/2 text-ultramarine"
        name="Trident"
        width="7.5rem"
      />
      <div className="relative z-1 flex flex-col gap-20 rounded-16 border border-saffron bg-white px-20 py-60">
        <label className="mb-20 block text-center font-serif text-h4" htmlFor="languages">
          {t('Text:language.Title')}
        </label>
        <Select
          id="languages"
          onChange={setSelectedLanguage}
          options={languages}
          placeholder={t('Text:language.SelectYourLanguage')}
          selectedOption={selectedLanguage}
        />
        {selectedLanguage && (
          <Button
            label={t('Buttons:validate.Button', { lng: selectedLanguage })}
            onClick={() => {
              changeLanguage(selectedLanguage);
              trackClick(TRACK_EVENT_NAMES.CLICK_LANGUAGE_CHOICE, { detail_click: 'validate' });
            }}
            theme="yellow"
          />
        )}
      </div>
    </div>
  );
};
