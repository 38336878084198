import { ComponentProps, FunctionComponent, useEffect } from 'react';

import {
  getProductOtherFacilitesEventsQuery,
  mapProductOtherFacilitiesEvents,
} from '@/api/events/events';
import { EventsCarousel } from '@/components/events-carousel/EventsCarousel';
import { SolidCardCarousel } from '@/components/solid-card-carousel/SolidCardCarousel';
import { useApp } from '@/contexts/AppContext';
import { useDirectus } from '@/hooks/use-service/useDirectus';

export const ProductOtherFacilitiesEvents: FunctionComponent<
  Omit<ComponentProps<typeof SolidCardCarousel>, 'items'>
> = (props) => {
  const { locale, productId } = useApp();

  const {
    data: events,
    error,
    fetch,
    isLoading,
  } = useDirectus({ mapper: mapProductOtherFacilitiesEvents });

  useEffect(() => {
    if (productId) {
      fetch({
        queryParams: {
          query: getProductOtherFacilitesEventsQuery({ locale, productId }),
        },
      });
    }
  }, [fetch, locale, productId]);

  return <EventsCarousel {...props} error={error} isLoading={isLoading} items={events || []} />;
};
