import { FunctionComponent, HTMLAttributes, useEffect, useRef } from 'react';

import { classnames } from '@/helpers/classnames';

interface Props extends HTMLAttributes<HTMLDivElement> {
  parent: HTMLElement | null | undefined;
  target: HTMLElement | null | undefined;
}

export const FloatingIndicator: FunctionComponent<Props> = ({
  className,
  parent,
  target,
  ...props
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updatePosition = () => {
      if (!target || !parent) {
        return;
      }

      const targetRect = target.getBoundingClientRect();
      const parentRect = parent.getBoundingClientRect();

      if (ref.current) {
        ref.current.style.transform = `translateX(${targetRect.left - parentRect.left}px)`;
        ref.current.style.width = `${targetRect.width}px`;
        ref.current.style.height = `${targetRect.height}px`;
      }
    };

    updatePosition();
  }, [parent, target]);

  return (
    <div
      {...props}
      className={classnames(
        'absolute bg-saffron',
        className,
        'start-0 transition-all duration-150',
      )}
      ref={ref}
      data-name="FloatingIndicator"
    />
  );
};
