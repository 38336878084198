import { FunctionComponent, HTMLAttributes } from 'react';

import { classnames } from '@/helpers/classnames';

interface Props extends HTMLAttributes<HTMLDivElement> {
  items: {
    endTime: string;
    id: string;
    label: string;
    onClick: () => void;
    startTime: string;
  }[];
  labels: { see: string };
}

export const PlanningList: FunctionComponent<Props> = ({ className, items, labels, ...props }) => {
  if (!items?.length) {
    return null;
  }

  return (
    <div
      {...props}
      className={classnames(className, 'columns-1 gap-60 space-y-20 md:columns-2 lg:columns-3')}
      data-name="PlanningList"
    >
      {items.map(({ endTime, id, label, onClick, startTime }) => (
        <button
          className="group block space-y-12 text-start"
          key={id}
          onClick={onClick}
          type="button"
        >
          <span className="block text-b2 font-semibold">
            {startTime} - {endTime}: {label}
          </span>
          <span className="text:black group-hover:text-middleGrey-active group-focus:text-middleGrey-active border-b text-b3 font-semibold transition-colors">
            {labels.see}
          </span>
        </button>
      ))}
    </div>
  );
};
