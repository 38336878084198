import { hasECSpace, isExclusiveCollection } from '@/helpers/product';

export const mapProduct = (product: APIProduct): Product => {
  const { comfort, description_2, map, resort_id, title, type } = product;

  return {
    id: resort_id,
    description: description_2,
    map,
    name: title,
    comfort: {
      hasExclusiveSpace: hasECSpace(product),
      isExclusiveCollection: isExclusiveCollection({ level: comfort?.level, type }),
      level: comfort?.level,
    },
  };
};
