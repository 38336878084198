import {
  Children,
  FunctionComponent,
  HTMLAttributes,
  isValidElement,
  PropsWithChildren,
  ReactElement,
} from 'react';

import { CarouselBody } from './Carousel.body';
import { CarouselProvider } from './Carousel.context';
import { CarouselControlNext } from './Carousel.control.next';
import { CarouselControlPrevious } from './Carousel.control.previous';
import { CarouselRoot } from './Carousel.root';

interface Props extends HTMLAttributes<HTMLDivElement> {
  initialIndex?: number;
  onPrevious?: () => void;
  onNext?: () => void;
}

const Carousel: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  initialIndex = 0,
  onNext,
  onPrevious,
  ...props
}) => {
  const body = Children.toArray(children).find(
    (child) =>
      isValidElement(child) &&
      typeof child.type === 'function' &&
      (child.type as React.FunctionComponent).displayName === 'CarouselBody',
  ) as ReactElement;

  const length = body?.props?.children?.length;

  return (
    <CarouselProvider
      initialIndex={initialIndex}
      onNext={onNext}
      onPrevious={onPrevious}
      length={length}
    >
      <CarouselRoot {...props}>{children}</CarouselRoot>
    </CarouselProvider>
  );
};

export { Carousel, CarouselBody, CarouselControlNext, CarouselControlPrevious };
