import { omit } from 'lodash';
import { FunctionComponent, HTMLAttributes, ReactNode, useRef, useState } from 'react';

import { FloatingIndicator } from '@/components/floating-indicator/FloatingIndicator';
import { classnames } from '@/helpers/classnames';

interface Option extends HTMLAttributes<HTMLInputElement> {
  activeClassName?: string;
  indicatorClassName?: string;
  label: ReactNode;
  value: string;
}

interface Props extends Omit<HTMLAttributes<HTMLFieldSetElement>, 'onChange'> {
  initialValue?: string;
  onChange?: (value: string) => void;
  name: string;
  options: Option[];
}

export const SegmentedControl: FunctionComponent<Props> = ({
  initialValue = '',
  className,
  name,
  onChange,
  options,
  ...props
}) => {
  const ref = useRef<HTMLFieldSetElement | null>(null);
  const [activeValue, setActiveValue] = useState(initialValue);
  const [targetRef, setTargetRef] = useState<HTMLLabelElement | null>(null);

  return (
    <fieldset
      {...props}
      className={classnames(
        'relative min-h-[48px] border-lightGrey',
        className,
        'isolate inline-flex flex-nowrap gap-0 overflow-hidden rounded-pill border p-4 font-semibold',
      )}
      data-name="SegmentedControl"
      ref={ref}
      role="radiogroup"
    >
      <FloatingIndicator
        className={classnames(
          options.find(({ value }) => value === activeValue)?.indicatorClassName,
          'rounded-pill',
        )}
        parent={ref.current}
        target={targetRef}
      />
      {options.map(({ activeClassName, label, value, ...option }) => (
        <label
          className={classnames(
            option.className,
            {
              [activeClassName || '']: activeValue === value,
            },
            'relative z-1 m-0 cursor-pointer rounded-pill px-20 py-8 transition-colors duration-150 ease-in',
            '[&:has(:focus-visible)]:ring',
          )}
          key={value}
          ref={activeValue === value ? (newRef) => setTargetRef(newRef) : null}
        >
          <input
            {...omit(option, ['indicatorClassName'])}
            className="sr-only"
            id={name}
            name={name}
            onChange={(e) => {
              setActiveValue(value);
              onChange?.(value);
              option.onChange?.(e);
            }}
            type="radio"
            value={value}
          />
          {label}
        </label>
      ))}
    </fieldset>
  );
};
