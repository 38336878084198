import { EXCLUSIVE_COMFORT_LEVEL, EXCLUSIVE_TYPES } from '@/constants/product';

export const isExclusiveComfort = (level: number): boolean =>
  Number(level) >= EXCLUSIVE_COMFORT_LEVEL;

export const isExclusiveType = (type: string): boolean => EXCLUSIVE_TYPES.includes(type);

export const isExclusiveCollection = ({ level, type }: { level: number; type: string }): boolean =>
  isExclusiveType(type) || isExclusiveComfort(level);

export const hasECSpace = (product: APIProduct): boolean =>
  product.areas?.some((area) => area.comfort.level >= EXCLUSIVE_COMFORT_LEVEL) &&
  !isExclusiveComfort(product.comfort?.level);
