import { ResizeObserver } from '@juggle/resize-observer';
import { FunctionComponent, PropsWithChildren, useId } from 'react';
import useMeasure from 'react-use-measure';

import { classnames } from '@/helpers/classnames';
import { useDisclosure } from '@/hooks/use-disclosure/useDisclosure';

interface Props {
  className?: string;
  collapsedHeight?: number;
  labels: {
    expand: string;
    reduce: string;
  };
  onClose?: () => void;
  onOpen?: () => void;
}

export const Spoiler: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  className,
  collapsedHeight = 100,
  labels,
  onClose,
  onOpen,
}) => {
  const id = useId();

  const [isOpen, { toggle }] = useDisclosure(false, { onClose, onOpen });
  const [ref, { height }] = useMeasure({ polyfill: ResizeObserver });

  const hasSpoiler = collapsedHeight < height;

  return (
    <>
      <div
        className={classnames(
          'min-h-[100px]',
          className,
          'overflow-hidden transition-heights duration-300 ease-in',
        )}
        role="region"
        style={hasSpoiler ? { height: isOpen ? height : collapsedHeight } : { height }}
      >
        <div id={id} ref={ref}>
          {children}
        </div>
      </div>
      {hasSpoiler && (
        <button
          aria-controls={id}
          aria-expanded={isOpen}
          className="text:black hover:text-middleGrey-active active:text-middleGrey-active focus:text-middleGrey-active border-b text-b3 font-semibold"
          onClick={() => toggle()}
          type="button"
        >
          {isOpen ? labels.reduce : labels.expand}
        </button>
      )}
    </>
  );
};
