import { FunctionComponent, HTMLAttributes } from 'react';

import { Facility } from '@/components/facility/Facility';
import { classnames } from '@/helpers/classnames';

interface Props extends HTMLAttributes<HTMLElement> {
  activities: Facilities;
}

export const Activities: FunctionComponent<Props> = ({ activities, className, ...props }) => {
  if (!activities?.length) {
    return null;
  }

  return (
    <section
      {...props}
      className={classnames(className, 'grid grid-cols-1 gap-20 sm:grid-cols-2 md:grid-cols-3')}
      data-name="Activities"
    >
      {activities.map((activity) => (
        <Facility facility={activity} key={activity.id} />
      ))}
    </section>
  );
};
