import { Spinner } from '@clubmed/trident-ui/molecules/Spinner';
import { FunctionComponent, useEffect, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { PLANNING_ENDPOINT } from '@/api/APIClient';
import { mapProductPlanning } from '@/api/product-planning/productPlanningMapper';
import { AppLayer } from '@/components/app-layer/AppLayer';
import { Card } from '@/components/card/Card';
import { DayPlanning } from '@/components/day-planning/DayPlanning';
import { PageContainer } from '@/components/page-container/PageContainer';
import { Text } from '@/components/text/Text';
import { useApp } from '@/contexts/AppContext';
import { parseAPIDateTime } from '@/helpers/dates';
import { useAPI } from '@/hooks/use-service/useAPI';

interface Props {
  dressCodes: Planning;
  onClose: () => void;
}

export const DayPlanningLayer: FunctionComponent<Props> = ({ dressCodes, onClose }) => {
  const { t } = useTranslation();
  const { locale, productId } = useApp();
  const id = useId();

  const {
    data: planning,
    fetch,
    isLoading,
  } = useAPI({ mapper: mapProductPlanning, url: PLANNING_ENDPOINT });

  useEffect(() => {
    if (productId) {
      fetch({
        queryParams: {
          date_format: 'ISO',
          first_date: dressCodes[0].startDateTime,
          last_date: dressCodes[0].endDateTime,
          type: 'ACTIVITY',
        },
      });
    }
  }, [dressCodes, fetch, productId]);

  const title = parseAPIDateTime(dressCodes[0].startDateTime)
    .toLocaleString(locale, {
      weekday: 'long',
    })
    .toUpperCase();

  return (
    <AppLayer
      aria-labelledby={id}
      header={<h2 id={id}>{title}</h2>}
      labels={{ close: t('Buttons:close.button') }}
      onClose={onClose}
    >
      <PageContainer className="space-y-40 py-40">
        {dressCodes.map((dressCode) => (
          <Card
            asHTMLText
            image={{
              src: dressCode.image,
            }}
            key={dressCode.id}
            text={dressCode.description}
            title={t('Text:text.ProgramToday', { dresscode: dressCode.label })}
          >
            <Text className="mt-12 text-b4 text-grey">{t('Text:disclamer.dresscode')}</Text>
          </Card>
        ))}
        {isLoading ? (
          <div className="flex w-full items-center justify-center p-40">
            <Spinner className="h-[30px] w-[30px]" />
          </div>
        ) : (
          <DayPlanning planning={planning || []} />
        )}
      </PageContainer>
    </AppLayer>
  );
};
