import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from '@/components/select/Select';
import { useApp } from '@/contexts/AppContext';
import { getLanguages } from '@/helpers/languages';

interface Props {
  className?: string;
}

export const SelectLanguages: FunctionComponent<Props> = ({ className }) => {
  const { t } = useTranslation();

  const { availableLocales, locale, changeLanguage } = useApp();
  const languages = availableLocales.map((lang) => ({
    id: lang,
    value: lang,
    label: getLanguages(t)[lang],
  }));

  return (
    <Select
      className={className}
      id="languages"
      onChange={changeLanguage}
      options={languages}
      placeholder={t('Text:language.SelectYourLanguage')}
      selectedOption={locale}
    />
  );
};
