import { type ImageSrcSetMap } from '@/components/image/Image';
import { IMAGE_TYPES } from '@/constants/image';

type ImageURLProps = {
  format?: string;
  height?: number;
  quality?: number;
  resolver?: string;
  src: string;
  width?: number;
};

export const getImageURL = ({
  format = 'webp',
  height,
  quality = 70,
  resolver = IMAGE_TYPES.MEDIA_SERVER,
  src,
  width = 1180,
}: ImageURLProps): string => {
  if (resolver === IMAGE_TYPES.DIRECTUS) {
    const url = new URL(src, process.env.REACT_APP_DIRECTUS_ASSETS_URL ?? '/');
    return url.toString();
  }

  const url = new URL(process.env.REACT_APP_MEDIA_SERVER_URL ?? '/');
  url.searchParams.set('url', src);
  url.searchParams.set('format', format);
  url.searchParams.set('width', String(width));
  if (height) {
    url.searchParams.set('height', String(height));
  }
  url.searchParams.set('quality', String(quality));
  return url.toString();
};

export const getSrcSet = (src: string, sizes?: ImageSrcSetMap, resolver?: string): string => {
  const defaultSizes = [
    [380, 70],
    [780, 70],
    [1180, 85],
  ];

  const map = sizes?.length ? sizes : defaultSizes;

  return map
    .map(([width, quality]) => `${getImageURL({ quality, resolver, src, width })} ${width}w`)
    .join(', ');
};
