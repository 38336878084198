import { FunctionComponent, HTMLAttributes, PropsWithChildren } from 'react';

import { classnames } from '@/helpers/classnames';

import { LayerCloseButton } from './LayerCloseButton';

interface Props extends HTMLAttributes<HTMLElement> {
  className?: string;
  labels: {
    close: string;
  };
  onClose: () => void;
}

export const LayerHeader: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  className,
  labels,
  onClose,
  ...props
}) => (
  <header
    {...props}
    className={classnames(
      className,
      'relative isolate flex h-[64px] w-full items-center justify-center bg-white px-20 text-b2 font-semibold',
    )}
  >
    <LayerCloseButton
      className="absolute start-0 z-1 mx-20 bg-white"
      label={labels.close}
      onClick={onClose}
    />
    {children}
  </header>
);
