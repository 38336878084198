import { FunctionComponent, PropsWithChildren } from 'react';
import Markdown from 'react-markdown';
import RemarkBreaks from 'remark-breaks';

import { classnames } from '@/helpers/classnames';

const ALLOWED_TAGS = ['del', 'br', 'em', 'li', 'ol', 'p', 'span', 'strong', 'ul'];

interface Props {
  asHTML?: boolean;
  children: string;
  className?: string;
}

export const Text: FunctionComponent<PropsWithChildren<Props>> = ({
  className,
  children,
  asHTML = false,
}) => {
  const classNames = classnames('space-y-8', className, 'prose');

  return asHTML ? (
    <div className={classNames} dangerouslySetInnerHTML={{ __html: children }} />
  ) : (
    <Markdown
      allowedElements={ALLOWED_TAGS}
      className={classNames}
      remarkPlugins={[RemarkBreaks]}
      skipHtml
      unwrapDisallowed
    >
      {children}
    </Markdown>
  );
};
