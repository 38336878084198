import { FunctionComponent, HTMLAttributes, PropsWithChildren } from 'react';

import { classnames } from '@/helpers/classnames';

interface Props extends HTMLAttributes<HTMLElement> {
  as?: 'div' | 'main' | 'section' | 'header' | 'footer';
  className?: string;
}

export const PageContainer: FunctionComponent<PropsWithChildren<Props>> = ({
  as: TagName = 'div',
  children,
  className,
  ...props
}) => {
  return (
    <TagName {...props} className={classnames('mx-auto w-full max-w-1220 px-20', className)}>
      {children}
    </TagName>
  );
};
