export const mapProductPlanning = (planning: APIPlanning): Planning => {
  return planning.map((event) => {
    const { description, end_date_time, id, image, label, start_date_time, type } = event;
    return {
      description,
      endDateTime: end_date_time,
      id,
      image,
      label,
      startDateTime: start_date_time,
      type,
    };
  });
};
