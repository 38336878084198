import { FunctionComponent, HTMLAttributes, useEffect } from 'react';

import { FACILITIES_ENDPOINT } from '@/api/APIClient';
import { mapFacilities } from '@/api/facilities/facilitiesMapper';
import { Facility } from '@/components/facility/Facility';
import { FramesGridSkeleton } from '@/components/frames-grid-skeleton/FramesGridSkeleton';
import { TRACK_EVENT_NAMES } from '@/constants/tracking';
import { useApp } from '@/contexts/AppContext';
import { classnames } from '@/helpers/classnames';
import { trackClick } from '@/helpers/tracking';
import { useAPI } from '@/hooks/use-service/useAPI';

export const ProductOtherFacilities: FunctionComponent<HTMLAttributes<HTMLElement>> = ({
  className,
  ...props
}) => {
  const { locale, productId } = useApp();

  const {
    data: facilities = [],
    error,
    fetch,
    isLoading,
  } = useAPI({ mapper: mapFacilities, url: FACILITIES_ENDPOINT });

  useEffect(() => {
    if (productId) {
      fetch({ queryParams: { filter: 'type==SERVICES', extended_catalog: true } });
    }
  }, [fetch, locale, productId]);

  if (isLoading) {
    return <FramesGridSkeleton />;
  }

  if (error || !facilities?.length) {
    return null;
  }

  return (
    <section
      {...props}
      className={classnames(className, 'grid grid-cols-1 gap-20 sm:grid-cols-2 md:grid-cols-3')}
      data-name="ProductOtherFacilities"
    >
      {facilities.map((facility) => (
        <Facility
          facility={facility}
          key={facility.id}
          onClick={() =>
            trackClick(TRACK_EVENT_NAMES.CLICK_TAB, {
              detail_click: `cards_others_facilities_${facility.name}`,
            })
          }
        />
      ))}
    </section>
  );
};
