import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { getLocalStorageItem, setLocalStorageItem } from '@/helpers/localStorage';

interface Context {
  availableLocales: readonly string[];
  changeLanguage: (language: string) => void;
  locale: string;
  productId: string;
  setProductId: (id: string) => void;
}

export const AppContext = createContext<Context | null>(null);

interface Props {
  availableLocales: string[];
}

export const AppProvider: FunctionComponent<PropsWithChildren<Props>> = ({
  availableLocales,
  children,
}) => {
  const { i18n } = useTranslation();
  const savedLanguage = getLocalStorageItem('selectedLanguage');
  const navigatorLanguage = navigator.language;

  const defaultLanguage = availableLocales.includes(navigatorLanguage)
    ? navigatorLanguage
    : i18n.language;

  const [locale, setLocale] = useState(savedLanguage || defaultLanguage);
  const [productId, setProductId] = useState('');

  useEffect(() => {
    if (savedLanguage && savedLanguage !== defaultLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [defaultLanguage, i18n, savedLanguage]);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setLocalStorageItem('selectedLanguage', language);
    setLocale(language);
  };

  const value = { availableLocales, changeLanguage, locale, productId, setProductId };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within a AppProvider');
  }
  return context;
};
