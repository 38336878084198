import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FACILITIES_ENDPOINT } from '@/api/APIClient';
import { mapFacilities } from '@/api/facilities/facilitiesMapper';
import { FramesGridSkeleton } from '@/components/frames-grid-skeleton/FramesGridSkeleton';
import { Restoration } from '@/components/restoration/Restoration';
import { TRACK_EVENT_NAMES } from '@/constants/tracking';
import { useApp } from '@/contexts/AppContext';
import { useExperience } from '@/contexts/ExperienceContext';
import { filterFacilitiesByTypes } from '@/helpers/facilities';
import { trackClick } from '@/helpers/tracking';
import { useAPI } from '@/hooks/use-service/useAPI';

export const ProductRestoration: FunctionComponent = () => {
  const { t } = useTranslation();
  const { productId } = useApp();
  const { isECMode } = useExperience();

  const {
    data: facilities,
    error,
    fetch,
    isLoading,
  } = useAPI({ mapper: mapFacilities, url: FACILITIES_ENDPOINT });

  useEffect(() => {
    if (productId) {
      fetch({ queryParams: { filter: 'type==BARS,RESTAURANTS', extended_catalog: true } });
    }
  }, [fetch, productId]);

  if (isLoading) {
    return <FramesGridSkeleton hasTitle />;
  }

  if (error || !facilities) {
    return null;
  }

  const track = (type: string) => () =>
    trackClick(TRACK_EVENT_NAMES.CLICK_TAB, {
      detail_click: 'cards_food_drinks',
      food_drinks_type: type,
    });

  return (
    <div className="space-y-40">
      <Restoration
        facilities={facilities.filter(filterFacilitiesByTypes(['RESTAURANTS'], isECMode))}
        onClick={track('restaurants')}
        title={t('Restaurants')}
      />
      <Restoration
        facilities={facilities.filter(filterFacilitiesByTypes(['BARS'], isECMode))}
        onClick={track('bars')}
        title={t('Bars')}
      />
    </div>
  );
};
