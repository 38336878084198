import { Children, Fragment, FunctionComponent, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

import { PORTAL_IDS } from '@/constants/html';
import { useIsClient } from '@/hooks/use-is-client/useIsClient';

interface Props {
  isDisabled?: boolean;
  key?: string;
  targetId?: string;
}

export const Portal: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  isDisabled = false,
  key,
  targetId = PORTAL_IDS.MAIN_PORTAL,
}) => {
  const isClient = useIsClient();

  if (!isClient) {
    return null;
  }

  const htmlElement = typeof document !== 'undefined' ? document.getElementById(targetId) : null;

  if (!htmlElement) {
    return null;
  }

  if (isDisabled) {
    return <Fragment>{children}</Fragment>;
  }

  return Children.count(children) ? createPortal(children, htmlElement, key) : null;
};
