import type { FunctionComponent, ImgHTMLAttributes } from 'react';

import { IMAGE_TYPES } from '@/constants/image';
import { getImageURL, getSrcSet } from '@/helpers/image';

export type ImageSrcSetMap = [width: number, quality: number][];

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  alt?: string;
  hasLazyLoad?: boolean;
  resolver?: string;
  sizes?: string;
  src: string;
  srcSetSizes?: ImageSrcSetMap;
}

type ImageSettings = {
  decoding: 'async' | 'auto' | 'sync' | undefined;
  loading: 'lazy' | 'eager' | undefined;
};

export const Image: FunctionComponent<Props> = ({
  alt = '',
  hasLazyLoad = true,
  resolver = IMAGE_TYPES.MEDIA_SERVER,
  sizes,
  src,
  srcSetSizes,
  ...rest
}) => {
  const settings: ImageSettings = hasLazyLoad
    ? { decoding: 'async', loading: 'lazy' }
    : { decoding: 'auto', loading: 'eager' };

  if (!src) {
    return null;
  }

  return (
    <img
      {...rest}
      alt={alt}
      decoding={settings.decoding}
      draggable="false"
      loading={settings.loading}
      src={getImageURL({ resolver, src })}
      srcSet={getSrcSet(src, srcSetSizes, resolver)}
      sizes={sizes}
    />
  );
};
