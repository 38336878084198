import { useCallback, useState } from 'react';

export const useDisclosure = (
  initial = false,
  callbacks?: { onOpen?: () => void; onClose?: () => void },
) => {
  const [isOpen, setIsOpen] = useState(initial);
  const { onOpen, onClose } = callbacks || {};

  const open = useCallback(() => {
    setIsOpen((isOpen) => {
      if (!isOpen) {
        onOpen?.();
        return true;
      }
      return isOpen;
    });
  }, [onOpen]);

  const close = useCallback(() => {
    setIsOpen((isOpen) => {
      if (isOpen) {
        onClose?.();
        return false;
      }
      return isOpen;
    });
  }, [onClose]);

  const toggle = useCallback(() => {
    isOpen ? close() : open();
  }, [close, open, isOpen]);

  return [isOpen, { open, close, toggle }] as const;
};
