import { format, parse } from 'date-fns';

const API_FORMATS = {
  DATE_TIME: "yyyyMMdd'T'HH:mm:ss.SSS",
  DATE_TIME_ISO: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
  TIME: "HH:mm:ss'",
};

const APP_FORMATS = {
  TIME: 'kk:mm',
};

// parse
export const parseAPIDateTime = (str: string): Date => {
  return parse(str, API_FORMATS.DATE_TIME, new Date());
};

export const parseAPIDateTimeISO = (str: string): Date => {
  return parse(str, API_FORMATS.DATE_TIME_ISO, new Date());
};

export const parseAPITime = (str: string): Date => {
  return parse(str, API_FORMATS.TIME, new Date());
};

// format
export const formatAPITime = (time: string): string => format(parseAPITime(time), APP_FORMATS.TIME);

export const formatAPIISOTime = (time: string): string =>
  format(parseAPIDateTimeISO(time), APP_FORMATS.TIME);

export const formatDateToAPIDateTime = (date: Date): string => format(date, API_FORMATS.DATE_TIME);
