import { useMemo } from 'react';

import { apiClient } from '@/api/APIClient';
import { useApp } from '@/contexts/AppContext';
import { useService, Params } from '@/hooks/use-service/useService';

export interface IUseAPI<T, K> {
  headers?: { [key: string]: string };
  mapper: (data: T) => K;
  params?: Params;
  url: string;
}

export const useAPI = <T, K>({ headers, mapper, params, url }: IUseAPI<T, K>) => {
  const { locale, productId } = useApp();

  const APIParams = useMemo(() => ({ product_id: productId, ...params }), [params, productId]);
  const APIHeaders = useMemo(() => ({ 'Accept-Language': locale, ...headers }), [headers, locale]);

  return useService({
    client: apiClient,
    headers: APIHeaders,
    mapper,
    params: APIParams,
    url,
  });
};
