import i18next from 'i18next';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { Route, useNavigate, Routes, useParams } from 'react-router-dom';

import { AppContext, useApp } from '@/contexts/AppContext';
import { getLocalStorageItem } from '@/helpers/localStorage';
import { LanguageSelectorPage } from '@/pages/LanguageSelectorPage';
import { NotFoundPage } from '@/pages/NotFoundPage';
import { ProductPage } from '@/pages/ProductPage';

const LanguageSelectorRoute = () => {
  const { productId = '' } = useParams();
  const storedLocale = getLocalStorageItem('selectedLanguage');
  const navigate = useNavigate();
  const { setProductId } = useApp();

  useEffect(() => {
    setProductId(productId.toUpperCase());
    if (storedLocale) {
      navigate(`/${storedLocale}/${productId}`, { replace: true });
    }
  }, [productId, storedLocale, navigate, setProductId]);

  if (!storedLocale) return <LanguageSelectorPage />;
  return null;
};

const ProductRoute = () => {
  const { locale = '', productId = '' } = useParams();
  const { availableLocales, changeLanguage, setProductId } = useApp();
  const isLocaleValid = availableLocales.includes(locale);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLocaleValid) {
      navigate('/not-found', { replace: true });
      return;
    }
    const storedLocale = getLocalStorageItem('selectedLanguage');
    if (locale !== storedLocale || i18next.language !== locale) {
      changeLanguage(locale);
    }
  }, [isLocaleValid, locale, navigate, changeLanguage]);

  useEffect(() => {
    setProductId(productId.toUpperCase());
  }, [productId, setProductId]);

  if (!isLocaleValid) {
    return null;
  }

  return <ProductPage />;
};

export const LocaleRouter: FunctionComponent = () => {
  const { availableLocales = [], locale, productId, setProductId, changeLanguage } = useApp();
  const navigate = useNavigate();

  const updateLocale = useCallback(
    (newLocale: string) => {
      changeLanguage(newLocale);
      if (productId) {
        navigate(`/${newLocale}/${productId}`, { replace: true });
      }
    },
    [productId, navigate, changeLanguage],
  );

  return (
    <AppContext.Provider
      value={{ availableLocales, locale, productId, setProductId, changeLanguage: updateLocale }}
    >
      <Routes>
        <Route path=":productId" element={<LanguageSelectorRoute />} />
        <Route path=":locale/:productId" element={<ProductRoute />} />
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AppContext.Provider>
  );
};
