import { Button } from '@clubmed/trident-ui/molecules/Buttons/Button';
import { useTabDispatch } from '@clubmed/trident-ui/molecules/Tabs/hooks/tabControl';
import { FunctionComponent, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading } from '@/components/heading/Heading';
import { Text } from '@/components/text/Text';
import { TRACK_EVENT_NAMES } from '@/constants/tracking';
import { classnames } from '@/helpers/classnames';
import { trackClick } from '@/helpers/tracking';

export const ProductMeetings: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  const { t } = useTranslation();

  const dispatch = useTabDispatch();

  return (
    <div {...props} className={classnames(className, 'space-y-12')}>
      <Heading className="text-b2 font-bold">{t('Titles:title.meetingsAndEvents')}</Heading>
      <Text className="text-b3">{t('Text:text.MeetingsAndEventsDescription')}</Text>
      <div className="!mt-20 text-center">
        <Button
          label={t('Buttons:button.PartOfAGroup')}
          onClick={() => {
            dispatch({ type: 'update', payload: 6 });
            trackClick(TRACK_EVENT_NAMES.CLICK_LANDING, {
              detail_click: 'part_of_group',
            });
          }}
          theme="yellow"
        />
      </div>
    </div>
  );
};
