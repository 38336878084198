export const enum TRACK_EVENT_NAMES {
  CLICK_ERROR = 'click_error',
  CLICK_LANDING = 'click_landing_page',
  CLICK_LANGUAGE_CHOICE = 'click_language_choice',
  CLICK_TAB = 'click_product_tabs',
}

export const enum TRACK_PAGE_NAMES {
  ERROR_PAGE = 'error_not_found',
  LANGUAGE_PAGE = 'language_page',
  PRODUCT_PAGE = 'landing_qr_code',
}
