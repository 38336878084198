import { FunctionComponent, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { AppLayer } from '@/components/app-layer/AppLayer';
import { FacilityDetails } from '@/components/facility-details/FacilityDetails';
import { PageContainer } from '@/components/page-container/PageContainer';

interface Props {
  onClose: () => void;
  facility: Facility;
}

export const FacilityLayer: FunctionComponent<Props> = ({ facility, onClose }) => {
  const { t } = useTranslation();
  const id = useId();

  return (
    <AppLayer
      aria-labelledby={id}
      header={<h2 id={id}>{facility.name}</h2>}
      labels={{ close: t('Buttons:close.button') }}
      onClose={onClose}
    >
      <PageContainer className="py-40">
        <FacilityDetails facility={facility} />
      </PageContainer>
    </AppLayer>
  );
};
