import { FunctionComponent } from 'react';

import { Skeleton } from '@/components/skeleton/Skeleton';
import { classnames } from '@/helpers/classnames';

interface Props {
  className?: string;
  hasTitle?: boolean;
}

export const FramesGridSkeleton: FunctionComponent<Props> = ({ className, hasTitle = false }) => (
  <section
    className={classnames(className, 'grid grid-cols-1 gap-20 sm:grid-cols-2 md:grid-cols-3')}
    data-name="FramesGridSkeleton"
  >
    {hasTitle && (
      <Skeleton className="col-span-1 max-w-[200px] sm:col-span-2 md:col-span-3 md:max-w-360" />
    )}
    <Skeleton className="aspect-square h-[350px] w-full rounded-16" />
    <Skeleton className="aspect-square h-[350px] w-full rounded-16" />
  </section>
);
