import { FunctionComponent, HTMLAttributes } from 'react';

import { Facility } from '@/components/facility/Facility';
import { Heading } from '@/components/heading/Heading';
import { classnames } from '@/helpers/classnames';

interface Props extends HTMLAttributes<HTMLElement> {
  facilities: Facilities;
  onClick?: () => void;
  title: string;
}

export const Restoration: FunctionComponent<Props> = ({
  className,
  facilities,
  onClick,
  title,
  ...props
}) => {
  if (!facilities?.length) {
    return null;
  }

  return (
    <section
      {...props}
      className={classnames(className, 'grid grid-cols-1 gap-20 sm:grid-cols-2 md:grid-cols-3')}
      data-name="Restoration"
    >
      <Heading className="col-span-1 text-h5 sm:col-span-2 md:col-span-3">{title}</Heading>
      {facilities.map((facility) => (
        <Facility facility={facility} key={facility.id} onClick={onClick} />
      ))}
    </section>
  );
};
