import { FunctionComponent, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { Activities } from '@/components/activities/Activities';
import { AppLayer } from '@/components/app-layer/AppLayer';
import { FacilityLayer } from '@/components/facility-layer/FacilityLayer';
import { PageContainer } from '@/components/page-container/PageContainer';

interface Props {
  activities: Facilities;
  header: string;
  onClose: () => void;
}

export const ActivitiesLayer: FunctionComponent<Props> = ({ activities, header, onClose }) => {
  const { t } = useTranslation();
  const id = useId();

  if (activities.length === 1) {
    return <FacilityLayer onClose={onClose} facility={activities[0]} />;
  }

  return (
    <AppLayer
      aria-labelledby={id}
      header={<h2 id={id}>{header}</h2>}
      labels={{ close: t('Buttons:close.button') }}
      onClose={onClose}
    >
      <PageContainer className="py-40">
        <Activities activities={activities} />
      </PageContainer>
    </AppLayer>
  );
};
