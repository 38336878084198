import { Button } from '@clubmed/trident-ui/molecules/Buttons/Button';
import { ComponentProps, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { classnames } from '@/helpers/classnames';
import { useWindowScroll } from '@/hooks/use-window-scroll/useWindowScroll';

export const GoToTop: FunctionComponent<ComponentProps<typeof Button>> = ({
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const [scroll, scrollTo] = useWindowScroll();

  return (
    <Button
      {...props}
      className={classnames(
        className,
        'ease fixed bottom-0 left-1/2 my-20 !-translate-x-1/2 bg-white transition-all duration-300',
        {
          '!translate-y-full opacity-0': scroll.y <= 10,
          'translate-y-0 opacity-100': scroll.y > 10,
        },
      )}
      onClick={(e) => {
        scrollTo({ y: 0 });
        props.onClick?.(e);
      }}
      theme="blackStroke"
    >
      {t('Buttons:button.goToTop')}
    </Button>
  );
};
