import { FunctionComponent, HTMLAttributes, PropsWithChildren } from 'react';
import Markdown from 'react-markdown';

const INLINE_TAGS = ['br', 'em', 'span', 'strong'];

interface Props extends HTMLAttributes<HTMLHeadingElement> {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  children: string;
}

export const Heading: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  level = 3,
  ...props
}) => {
  const TagName = `h${level}`;

  return (
    <TagName {...props}>
      <Markdown allowedElements={INLINE_TAGS} skipHtml unwrapDisallowed>
        {children}
      </Markdown>
    </TagName>
  );
};
