import { isAfter, isBefore, parse } from 'date-fns';

export const getCurrentWeekDay = (): string => {
  return new Date()
    .toLocaleString('en-US', {
      weekday: 'long',
    })
    .toUpperCase();
};

export const isNow = ({ opening, closing }: { opening: string; closing: string }) => {
  const now = new Date();
  const open = parse(opening, 'HH:mm:ss', now);
  const close = parse(closing, 'HH:mm:ss', now);

  return isAfter(now, open) && isBefore(now, close);
};
