import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { AppProvider } from '@/contexts/AppContext';

import App from './App';
import i18next from './i18n';
import './styles/global.css';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root') as HTMLElement);
const availableLocales = process.env.REACT_APP_AVAILABLE_LOCALES?.split(',') || [];

root.render(
  <I18nextProvider i18n={i18next}>
    <AppProvider availableLocales={availableLocales}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AppProvider>
  </I18nextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
