import { Tabs } from '@clubmed/trident-ui/molecules/Tabs/Tabs';
import { TabsBody } from '@clubmed/trident-ui/molecules/Tabs/TabsBody';
import { TabsHeader } from '@clubmed/trident-ui/molecules/Tabs/TabsHeader';
import { TabsHeading } from '@clubmed/trident-ui/molecules/Tabs/TabsHeading';
import { TabsPanel } from '@clubmed/trident-ui/molecules/Tabs/TabsPanel';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductActivities } from '@/components/product-activities/ProductActivities';
import { ProductMeetingAndEvents } from '@/components/product-meeting-and-events/ProductMeetingAndEvents';
import { ProductOtherFacilities } from '@/components/product-other-facilities/ProductOtherFacilities';
import { ProductOtherFacilitiesEvents } from '@/components/product-other-facilities-events/ProductOtherFacilitiesEvents';
import { ProductOverview } from '@/components/product-overview/ProductOverview';
import { ProductProgram } from '@/components/product-program/ProductProgram';
import { ProductRestoration } from '@/components/product-restoration/ProductRestoration';
import { ProductRestorationEvents } from '@/components/product-restoration-events/ProductRestorationEvents';
import { TRACK_EVENT_NAMES } from '@/constants/tracking';
import { trackClick } from '@/helpers/tracking';
import { useMediaQuery } from '@/hooks/use-media-query/useMediaQuery';

interface Props {
  product: Product;
  selected?: number;
}

export const ProductTabs: FunctionComponent<Props> = ({ product, selected = 1 }) => {
  const [clicked, setClicked] = useState([selected]);
  const { t } = useTranslation();

  const onSelect = ({ id, value }: { id: string; value: number }) => {
    if (!clicked.includes(value)) {
      const l = [...clicked, value];
      setClicked(l);
    }
    trackClick(TRACK_EVENT_NAMES.CLICK_TAB, { detail_click: `tab_${id}` });
  };

  const items = [
    {
      id: 'resort',
      label: t('Tabs:tabs.TheResort'),
      value: 1,
      content: <ProductOverview product={product} />,
    },
    {
      id: 'food_drinks',
      label: t('Tabs:tabs.RestaurantsAndBars'),
      value: 2,
      content: (
        <div className="space-y-40">
          <ProductRestorationEvents />
          <ProductRestoration />
        </div>
      ),
    },
    {
      id: 'activities',
      label: t('Tabs:tabs.Activities'),
      value: 3,
      content: <ProductActivities />,
    },
    {
      id: 'others_facilities',
      label: t('Tabs:tabs.OtherFacilities'),
      value: 4,
      content: (
        <div className="space-y-40">
          <ProductOtherFacilitiesEvents />
          <ProductOtherFacilities />
        </div>
      ),
    },
    { id: 'program', label: t('Tabs:tabs.Program'), value: 5, content: <ProductProgram /> },
    {
      id: 'meeting_events',
      label: t('Tabs:tabs.MeetingsAndEvents'),
      value: 6,
      content: <ProductMeetingAndEvents />,
    },
  ];

  const isSmallDevice = useMediaQuery('only screen and (max-width : 640px)');

  return (
    <Tabs max={6} min={1} selected={selected}>
      <TabsHeader className="px-20 lg:px-0" constrained={!isSmallDevice}>
        {items.map((item) => (
          <TabsHeading
            key={item.value}
            label={item.label}
            onSelect={(c) => onSelect({ ...c, id: item.id })}
            theme="black"
            value={item.value}
          />
        ))}
      </TabsHeader>
      <TabsBody className="px-20 lg:px-0">
        {items.map((item) => (
          <TabsPanel key={item.value} value={item.value}>
            {clicked.includes(item.value) && item.content}
          </TabsPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
};
