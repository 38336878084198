import { Fragment, FunctionComponent } from 'react';

import { StaffLayer } from '@/components/staff-layer/StaffLayer';
import { StaffMember } from '@/components/staff-member/StaffMember';
import { useDisclosure } from '@/hooks/use-disclosure/useDisclosure';

interface Props {
  onClick?: () => void;
  staff: Staff;
}

export const Staff: FunctionComponent<Props> = ({ onClick, staff }) => {
  const [isOpen, { close, open }] = useDisclosure(false);

  if (!staff?.length) {
    return null;
  }

  return (
    <Fragment>
      <StaffMember
        className="w-full"
        onClick={() => {
          open();
          onClick?.();
        }}
        image={{
          sizes:
            '(max-width: 640px) 100vw, (max-width: 768px) 50vw, (max-width: 1220px) 33vw, 380px',
        }}
        member={staff[0]}
      />
      {isOpen && <StaffLayer onClose={close} staff={staff} />}
    </Fragment>
  );
};
